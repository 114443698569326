import { createContext, useContext } from 'react'

export interface Attribution {
  gclid?: string
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_name?: string
  utm_source?: string
  utm_term?: string
}

export const buildAttributionQueryString = (
  attribution: Attribution
): string => {
  if (attribution) {
    let queryStringParams = []

    for (let [key, value] of Object.entries(attribution)) {
      queryStringParams.push(`${key}=${value}`)
    }

    return queryStringParams.length > 0 ? `?${queryStringParams.join('&')}` : ``
  }
}

const AttributionContext = createContext<Attribution>(null)
export const AttributionProvider = AttributionContext.Provider

export const useAttribution = () => {
  const attribution = useContext(AttributionContext)

  return attribution
}

export default AttributionContext
