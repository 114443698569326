import { FunctionComponent, useEffect, useState } from 'react'
import Link from 'next/link'

import {
  Buttons,
  Collapseable,
  HeaderContainer,
  LinksList,
  Logo,
  Toggle,
  ToggleBar,
  Wrapper,
} from '@components/page/header/style'
import { PrimaryLink, SecondaryLink } from '@components/undock/buttons'
import { ModuleContainer, themesMap } from '@components/undock/modules'
import { useRouter } from 'next/dist/client/router'
import {
  buildAttributionQueryString,
  useAttribution,
} from '@context/attribution'

interface HeaderPropsI {
  isLight?: boolean
  shouldScroll?: boolean
}

const Header: FunctionComponent<HeaderPropsI> = ({
  isLight = false,
  shouldScroll = false,
}) => {
  const attribution = useAttribution()
  const router = useRouter()

  const [currentPage, setCurrentPage] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState<boolean>(false)

  const handleResize = () => {
    setIsOpen(isOpen && window.innerWidth < 769)
  }

  const handleScroll = (_e: Event): void => {
    const scrolled = window.scrollY >= 30

    setIsScrolled(scrolled)
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (router) {
      setCurrentPage(router.asPath.split('?')[0])
      router.beforePopState(({ url, as, options }) => {
        setCurrentPage(as)
        return true
      })
    }
  }, [router])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Wrapper
      className={`${shouldScroll && isScrolled ? 'scrolled' : 'unscrolled'}${
        isOpen ? ' open' : ''
      }`}
      isLight={isLight}
      isOpen={isOpen}
      isScrolled={shouldScroll && isScrolled}
    >
      <HeaderContainer>
        <Link href={`/l/${buildAttributionQueryString(attribution)}`}>
          <a>
            {(!isLight || isOpen) && (
              <Logo src="/img/logo-white.svg" alt="Undock" title="Undock" />
            )}
            {isLight && !isOpen && (
              <Logo src="/img/logo-primary.svg" alt="Undock" title="Undock" />
            )}
          </a>
        </Link>

        <Toggle isLight={isLight} onClick={toggleOpen}>
          <ToggleBar isOpen={isOpen} />
          <ToggleBar isOpen={isOpen} />
        </Toggle>

        <Collapseable className={isOpen ? 'open' : ''}>
          <LinksList isLight={isLight} isOpen={isOpen}>
            <Link
              href={`/l/features/${buildAttributionQueryString(attribution)}`}
            >
              <a className={`${currentPage === '/features' ? `active` : ''}`}>
                Features
              </a>
            </Link>
            <Link
              href={`/l/pricing/${buildAttributionQueryString(attribution)}`}
            >
              <a className={`${currentPage === '/pricing' ? `active` : ''}`}>
                Pricing
              </a>
            </Link>
            <a
              href={`https://phase.undock.com/${buildAttributionQueryString(
                attribution
              )}`}
              target="_blank"
            >
              Phase
            </a>
          </LinksList>
          <Buttons isLight={isLight} isOpen={isOpen}>
            <SecondaryLink
              borderless
              href={`https://app.undock.com/login${buildAttributionQueryString(
                attribution
              )}`}
              style={{
                height: '2.5rem',
                padding: '0.5rem 1rem',
              }}
              theme={isLight ? themesMap.light : themesMap.dark}
            >
              Sign in
            </SecondaryLink>
            <SecondaryLink
              href={`https://app.undock.com/signup${buildAttributionQueryString(
                attribution
              )}`}
              style={{
                height: '2.5rem',
                padding: '0.5rem 1rem',
              }}
              theme={isLight ? themesMap.light : themesMap.dark}
            >
              Create an account
            </SecondaryLink>
          </Buttons>
        </Collapseable>
      </HeaderContainer>
    </Wrapper>
  )
}

export default Header
