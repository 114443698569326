import styled from '@emotion/styled'
import { mediaQueries } from '@lib/mediaQueries'

interface ColumnPropsI {}

export const Column = styled.div<ColumnPropsI>(({

}) => (mediaQueries({
  padding: '1rem',
  width: [
    '100%'
  ]
})))

interface FlexPropsI {
  alignItems?: 'center' | 'end' | 'start',
  flexWrap?: string[] | 'wrap' | 'nowrap'
  justifyContent?: 'center' | 'space-around' | 'space-between'
}

export const Flex = styled.div<FlexPropsI>(({
  alignItems = 'center',
  flexWrap = 'nowrap',
  justifyContent = 'center'
}) => (mediaQueries({
  alignItems,
  display: 'flex',
  flexWrap,
  justifyContent,
})))