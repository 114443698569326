import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import {
  colorBlack,
  colorBorderLight,
  colorGrayscaleDark2,
  colorGrayscaleLight3,
  colorTextDark,
  colorTextLabel,
  colorTextLight,
  gold,
} from '@lib/colors'
import { mediaQueries } from '@lib/mediaQueries'
import { linearGradient, rgba } from 'polished'
import { ClassAttributes, FunctionComponent, HTMLAttributes } from 'react'

export interface ThemeColors {
  backgroundColor: string
  cardBackgroundColor: string
  cardBorderColor: string
  cardLabelColor: string
  cardTextColor: string
  headlineColor: string
  textColor: string
  primaryButtonBackground: string
  primaryButtonColor: string
}

export interface Theme {
  colors: ThemeColors
}

export type AllowedTextAlign = 'center' | 'left' | 'right'

export type AllowedThemes = 'dark' | 'light'

type ThemesMap = {
  [key in AllowedThemes]: Theme
}

export const themesMap: ThemesMap = {
  dark: {
    colors: {
      backgroundColor: colorTextDark,
      cardBackgroundColor: colorBlack,
      cardBorderColor: colorBlack,
      cardLabelColor: 'white',
      cardTextColor: colorGrayscaleLight3,
      headlineColor: 'white',
      textColor: colorTextLight,
      primaryButtonBackground: gold,
      primaryButtonColor: colorTextDark,
    },
  },
  light: {
    colors: {
      backgroundColor: 'white',
      cardBackgroundColor: 'white',
      cardBorderColor: colorBorderLight,
      cardLabelColor: colorTextLabel,
      cardTextColor: colorGrayscaleDark2,
      headlineColor: colorTextDark,
      textColor: colorTextLight,
      primaryButtonBackground: colorBlack,
      primaryButtonColor: 'white',
    },
  },
}

export const getTheme = (themeName: AllowedThemes) =>
  themesMap[themeName].colors

export const ModuleContainer = styled.div(
  mediaQueries({
    margin: 'auto',
    paddingTop: ['2rem', '2rem', 0],
    width: ['100%', '100%', '90%', '80%', '70%'],
  })
)

export const ModuleHeadline = styled.h2({
  fontSize: '54px',
  fontWeight: 500,
  lineHeight: '62px',
})

export const ModuleSubheadline = styled.p({
  color: colorTextLabel,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '-0.02em',
  lineHeight: '30px',
})

interface ModulePropsI
  extends ClassAttributes<HTMLElement>,
    HTMLAttributes<HTMLElement> {
  backgroundImage?: string
  backgroundSize?: string
  theme?: Theme
  withGradient?: boolean
  withoutTopPadding?: boolean
}

const ModuleComponent = styled.section<ModulePropsI>(
  {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    width: '100%',
  },
  ({
    backgroundImage = null,
    backgroundSize = null,
    theme = themesMap.light,
    withGradient = false,
    withoutTopPadding = false,
  }) => {
    const themeProps = theme.colors

    const styles: any = {
      backgroundColor: themeProps.backgroundColor,
      backgroundSize: backgroundSize ? backgroundSize : 'cover',
      color: themeProps.textColor,
      display: 'flex',
      padding: withoutTopPadding ? '0 2rem 2rem' : '2rem',
      position: 'relative',
      h1: {
        color: themeProps.headlineColor,
      },
      h2: {
        color: themeProps.headlineColor,
      },
      h3: {
        color: themeProps.headlineColor,
      },
      h4: {
        color: themeProps.headlineColor,
      },
      h5: {
        color: themeProps.headlineColor,
      },
    }

    if (backgroundImage) {
      styles.backgroundImage = `url("${backgroundImage}")`
    }

    if (withGradient) {
      styles[':before'] = {
        ...linearGradient({
          colorStops: [
            `${rgba(themeProps.backgroundColor, 0)} 0%`,
            `${rgba(themeProps.backgroundColor, 0)}  30%`,
            `${rgba(themeProps.backgroundColor, 1)} 100%`,
          ],
          toDirection: 'to bottom',
        }),
        content: '""',
        display: 'block',
        height: '100%',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 0,
      }
    }

    return styles
  }
)

export const ChildContainer = styled.div(
  mediaQueries({
    margin: 'auto',
    maxWidth: '1920px',
    width: '100%',
  })
)

const Module: FunctionComponent<ModulePropsI> = (props) => {
  return (
    <ThemeProvider theme={props.theme}>
      <ModuleComponent {...props}>
        <ChildContainer>{props.children}</ChildContainer>
      </ModuleComponent>
    </ThemeProvider>
  )
}

export default Module
