/*______COLOR var________*/

export const primaryColor = '#402f6f'
export const primaryColorBlack = '#07112D'

export const colorFooterLight = '#a99fc5'

export const colorBlack = '#121114'
export const colorTextDark = '#13151A'
export const colorTextMedium = '#767B83'
export const colorTextLabel = '#878E9C'
export const colorTextLight = '#5A5B5C'
export const colorTextLight4 = '#a7a7a8'
export const colorBackgroundLight = '#dadbdb'
export const colorBackgroundLight2 = '#f4f4f4'
export const colorBackgroundDarkGrey = '#27292D'
export const colorBorderLight = '#EDEAF2'

export const colorGrayscaleDark1 = '#807A8E'
export const colorGrayscaleDark2 = '#9A95AC'
export const colorGrayscaleDark3 = '#A59FB9'

export const colorButtonBackground = '#373B41'

export const purple = '#4631C9'
export const gold = '#DFC051'
export const black = '#2A2A2A'
export const white = '#ffffff'
export const blue = '#4E83CB'

export const colorGrayscaleBlack = '#231647'
export const colorGrayscaleGray = '#6a7c92'
export const colorThemePrimary = '#713FFF'
export const colorThemePrimaryDark3 = '#3A2868'
export const colorThemePrimaryLight = '#9874FF'
export const colorThemePrimaryExtraLight = '#C5B0FF'
export const colorGrayscaleWhite = '#FFFFFF'
export const colorGrayscaleDark = '#8473B5'
export const colorGrayscaleDarkPopup = '#433569'
export const colorGrayscaleLightDark = '#9687c7'
export const colorGrayscaleLight = '#CCC5E3'
export const colorGrayscaleLight2 = '#E2DFF2'
export const colorGrayscaleLight3 = '#8C8A90'
export const colorGrayscaleLightBg = '#F7F7FC'
export const colorGrayscaleLightestBg = '#F5F5FC'
export const colorPurple = '#515EA0'
export const colorDarkPurple = '#402F6F'
export const colorPrimaryMain = '$color-dark-purple'
export const colorWarningOrange = '#EEBE72'

export const colorDarkPrimaryLight3 = '#E9E1FF'

export const colorAlert = '#F13693'
export const colorGray = '#E4DFF2'
export const colorInfo = '#37B7FF'
export const colorSuccess = '#16C888'
export const colorWarning = '#FFB03A'

export const sidebarPurple = '#11112E'
export const bgDarkPurple = '#1C1C4F'
export const bgLightGrey = '#F8F8FB'
export const bgBleedPurple = '#F4F4FC'

export const textDarkPurple = '#20205a'
export const textBleedPurple = '#515EA0'
export const textWhite = '#FAFAFE'
export const textGrayDisabled = '#BCC2CF'
export const textPurpleActive = '#413395'
