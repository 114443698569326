import { FunctionComponent } from 'react'
import { PrimaryLink } from '@components/undock/buttons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@components/undock/ctas/signup/style'
import { useTheme } from '@emotion/react'
import { Theme } from '@components/undock/modules'
import {
  Attribution,
  buildAttributionQueryString,
  useAttribution,
} from '@context/attribution'

interface SignupButtonPropsI {
  text?: string
}

const SignupButton: FunctionComponent<SignupButtonPropsI> = ({
  text = 'Get started',
}) => {
  const attribution = useAttribution()
  const theme: Theme = useTheme() as Theme

  return (
    <>
      {attribution && (
        <PrimaryLink
          href={`https://app.undock.com/signup${buildAttributionQueryString(
            attribution
          )}`}
          theme={theme}
        >
          {text}
          <Icon icon={faArrowRight} />
        </PrimaryLink>
      )}
    </>
  )
}

export default SignupButton
