import { Column as FlexColumn } from '@components/undock/flex'
import { themesMap } from '@components/undock/modules'
import styled from '@emotion/styled'
import { colorGrayscaleDark2, colorGrayscaleLight3 } from '@lib/colors'
import { mediaQueries } from '@lib/mediaQueries'

export const Column = styled(FlexColumn)(
  mediaQueries({
    width: ['100%', '100%', '50%', '25%'],
  })
)

export const CompanyMission = styled.p({
  color: colorGrayscaleLight3,
  fontSize: '.875rem',
  fontWeight: 400,
  lineHeight: 2,
  marginTop: '2rem',
})

export const Container = styled.div(
  mediaQueries({
    alignItems: 'flex-start',
    borderTop: `solid 1px ${colorGrayscaleDark2}`,
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap'],
    justifyContent: 'space-between',
    margin: 'auto',
    padding: '4rem 0',
    width: ['100%', '100%', '90%', '80%', '70%'],
  })
)

export const FooterLink = styled.a({
  color: colorGrayscaleLight3,
  display: 'inline-block',
  fontSize: '.875rem',
  fontWeight: 400,
  padding: '.5rem 0',
  position: 'relative',
  textDecoration: 'none',
  transition: 'color 0.35s',
  '&:hover': {
    color: 'white',
  },
})

export const Heading = styled.h2({
  fontFamily: '"Inter", sans-serif !important',
  fontSize: '.75rem',
  fontWeight: `${600} !important` as any,
  letterSpacing: 'calc(1rem/16)',
  marginBottom: '2rem!important',
})

export const LinkList = styled.ul({
  margin: 0,
  padding: 0,
})

export const LinkListItem = styled.li({
  listStyle: 'none',
  '&:not(:first-of-type)': {
    marginTop: '0.25rem',
  },
})

export const Logo = styled.img({
  maxHeight: '1rem',
})

interface WrapperPropsI {}

export const Wrapper = styled.footer<WrapperPropsI>(({}) =>
  mediaQueries({
    backgroundColor: themesMap.dark.colors.backgroundColor,
    color: 'white',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap'],
    marginLeft: 0,
    transition: 'margin-left 0.35s, width 0.35s',
    width: '100%',

    h2: {
      marginTop: 0,
    },
    [`${Column}:not(:first-of-type)`]: {
      flexShrink: 1,
      marginLeft: [0, 0, '7rem'],
    },
  })
)
