import { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react'
import Error from 'next/error'

import Footer from '@components/page/footer'
import Header from '@components/page/header'
import { Container } from '@components/page/style'
import { Attribution, AttributionProvider } from '@context/attribution'
import { analytics } from 'googleapis/build/src/apis/analytics'

export interface PageErrorI {
  code: number
  message: string
}

interface PagePropsI {
  error?: PageErrorI
  hasHeader?: boolean
  hasViewToggler?: boolean
  lightHeader?: boolean
  shouldScroll?: boolean
}

const Page: FunctionComponent<PagePropsI> = ({
  children,
  error = null,
  hasHeader = true,
  lightHeader = false,
  shouldScroll = false,
}) => {
  const [attribution, setAttribution] = useState<Attribution>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      ;(window as any).analytics.page()
    }
  })

  useLayoutEffect(() => {
    if (!attribution) {
      const query = new URLSearchParams(location.search)
      const attribution: Attribution = {}

      if (query.has('gclid')) attribution.gclid = query.get('gclid')
      if (query.has('utm_campaign'))
        attribution.utm_campaign = query.get('utm_campaign')
      if (query.has('utm_content'))
        attribution.utm_content = query.get('utm_content')
      if (query.has('utm_medium'))
        attribution.utm_medium = query.get('utm_medium')
      if (query.has('utm_name')) attribution.utm_name = query.get('utm_name')
      if (query.has('utm_source'))
        attribution.utm_source = query.get('utm_source')
      if (query.has('utm_term')) attribution.utm_term = query.get('utm_term')

      setAttribution(attribution)

      setTimeout(() => {
        ;(window as any).analytics.track('Pageview Threshold Met', {
          path: location.pathname,
          query: location.search,
          category: document.title.replace('Undock | ', ''),
          label: location.search,
        })
      }, 5000)
    }
  }, [attribution])

  return (
    <AttributionProvider value={attribution}>
      {error && <Error statusCode={error.code} />}
      {!error && (
        <>
          {hasHeader && (
            <Header isLight={lightHeader} shouldScroll={shouldScroll} />
          )}
          <Container hasHeader={hasHeader}>{children}</Container>
          <Footer />
        </>
      )}
    </AttributionProvider>
  )
}

export default Page
