import { FunctionComponent } from 'react'
import Link from 'next/link'

import {
  CompanyMission,
  Container,
  FooterLink,
  Heading,
  LinkList,
  LinkListItem,
  Logo,
  Wrapper,
} from '@components/page/footer/style'
import { Column } from '@components/undock/flex'
import {
  buildAttributionQueryString,
  useAttribution,
} from '@context/attribution'

interface FooterPropsI {}

const Footer: FunctionComponent<FooterPropsI> = ({}) => {
  const attribution = useAttribution()

  return (
    <Wrapper>
      <Container>
        <Column>
          <Logo alt="Undock" src="/img/logo-white.svg" title="Undock" />

          <CompanyMission>
            Copyright &copy; {new Date().getFullYear()} Undock, Inc.
          </CompanyMission>
        </Column>
        <Column>
          <Heading>Company</Heading>

          <LinkList>
            <LinkListItem>
              <FooterLink
                href="https://angel.co/undock"
                rel="noopener"
                target="_blank"
              >
                Work at Undock
              </FooterLink>
            </LinkListItem>
            <LinkListItem>
              <Link
                href={`/l/contact-us/${buildAttributionQueryString(
                  attribution
                )}`}
              >
                <FooterLink>Contact us</FooterLink>
              </Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <Heading>Resources</Heading>

          <LinkList>
            <LinkListItem>
              <FooterLink
                href="https://undock.crisp.help"
                rel="noopener"
                target="_blank"
              >
                Help center
              </FooterLink>
            </LinkListItem>
            <LinkListItem>
              <FooterLink
                href={`/l/legal/terms-of-service/${buildAttributionQueryString(
                  attribution
                )}`}
              >
                Terms of Service
              </FooterLink>
            </LinkListItem>
            <LinkListItem>
              <FooterLink
                href={`/l/legal/privacy-policy/${buildAttributionQueryString(
                  attribution
                )}`}
              >
                Privacy Policy
              </FooterLink>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <Heading>Content</Heading>

          <LinkList>
            <LinkListItem>
              <FooterLink href="https://one.undock.com/">
                Undock I: A Time Odyssey
              </FooterLink>
            </LinkListItem>
            <LinkListItem>
              <FooterLink href="https://two.undock.com/">
                Undock II: Now & Forever
              </FooterLink>
            </LinkListItem>
            <LinkListItem>
              <FooterLink href="https://phase.undock.com/">
                Phase Blog
              </FooterLink>
            </LinkListItem>
          </LinkList>
        </Column>
      </Container>
    </Wrapper>
  )
}

export default Footer
