import styled from '@emotion/styled'
import { mediaQueries } from '@lib/mediaQueries'

export const ButtonsWrapper = styled.div(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: ['2rem', '2rem', 0, 0, 0],
    width: ['100%', '100%', '35%', '35%', '35%'],
  })
)

export const ContentWrapper = styled.div(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    width: ['100%', '100%', '45%', '45%', '45%'],
  })
)

export const Headline = styled.h2({
  fontSize: '3.375rem',
})

export const UpsellWrapper = styled.div(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    justifyContent: 'space-between',
    margin: 'auto',
    width: ['100%', '100%', '90%', '80%', '70%'],
  })
)
