import { FunctionComponent } from 'react'
import Module, { AllowedThemes, themesMap } from '@components/undock/modules'
import {
  ButtonsWrapper,
  ContentWrapper,
  Headline,
  UpsellWrapper,
} from '@components/undock/modules/upsell/style'
import SignupButton from '@components/undock/ctas/signup'
import { UpsellModuleI } from '@lib/contentful/entries'

interface UpsellModulePropsI extends UpsellModuleI {
  themeName: AllowedThemes
}

const UpsellModule: FunctionComponent<UpsellModulePropsI> = ({
  headline = null,
  id = 'NO_ID_PROVIDED',
  themeName = 'light',
}) => {
  return (
    <Module id={id} theme={themesMap[themeName]}>
      <UpsellWrapper>
        <ContentWrapper>
          {headline && <Headline>{headline}</Headline>}
        </ContentWrapper>
        <ButtonsWrapper>
          <SignupButton />
        </ButtonsWrapper>
      </UpsellWrapper>
    </Module>
  )
}

export default UpsellModule
