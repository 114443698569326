import {
  ChildContainer,
  ModuleContainer,
  themesMap,
} from '@components/undock/modules'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { colorTextLight4, gold } from '@lib/colors'
import { mediaQueries } from '@lib/mediaQueries'

const scrolledAnimation = keyframes({
  from: {
    transform: 'translateY(-2rem)',
  },
  to: {
    transform: 'translateY(0)',
  },
})

const unscrolledAnimation = keyframes({
  from: {
    transform: 'translateY(2rem)',
  },
  to: {
    transform: 'translateY(0)',
  },
})

interface ButtonsPropsI {
  isLight?: boolean
  isOpen?: boolean
}
export const Buttons = styled.div<ButtonsPropsI>(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    justifyContent: ['center', 'center', 'center', 'flex-end'],
    margin: ['1rem auto 0', '1rem auto 0', '1rem auto 0', '0'],
    padding: ['0 1rem 1rem', '0 1rem 1rem', '0 1rem 1rem', 0],
    width: ['100%', '100%', '100%', '50%'],
    a: {
      fontSize: '.875rem',
      padding: '1rem 1.5rem',
      width: ['100%', '100%', 'calc(50% - 2rem)', 'auto'],
      '&:first-of-type': {
        marginLeft: ['0', '1rem', '1rem', 0],
      },
      '&:last-of-type': {
        marginRight: ['0', '1rem', '1rem', 0],
      },
      '&:not(:first-of-type)': {
        marginLeft: [0, 0, '1rem'],
      },
    },
  }),
  ({ isLight = false, isOpen = false }) => {
    const styles = {}

    if (isOpen) {
      styles['a'] = {
        color: 'white',
      }
    }

    return styles
  }
)

export const HeaderContainer = styled.div(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: '1920px',
    position: 'relative',
    width: ['100%', '100%', '90%', '80%', '70%'],
    zIndex: 2,
  })
)

export const Collapseable = styled.div(
  mediaQueries({
    backgroundColor: [
      themesMap.dark.colors.backgroundColor,
      themesMap.dark.colors.backgroundColor,
      themesMap.dark.colors.backgroundColor,
      'transparent',
    ],
    display: 'flex',
    flexDirection: [
      'column-reverse',
      'column-reverse',
      'column-reverse',
      'row',
    ],
    flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap'],
    flexShrink: 1,
    margin: ['0 -1rem', '0 -1rem', '0 -1rem', 0],
    overflow: 'hidden',
    position: ['absolute', 'absolute', 'absolute', 'relative'],
    top: ['3rem', '3rem', '3rem', 0],
    transition: 'transform .7s ease-in',
    transform: [
      'translateY(-100vh)',
      'translateY(-100vh)',
      'translateY(-100vh)',
      'none',
    ],
    width: ['100vw', '100vw', '100vw', 'calc(80%)'],
    zIndex: -1,
    '&.open': {
      transform: 'translateY(0)',
    },
  })
)

export const HeaderLink = styled.a({})

interface LinksListPropsI {
  isLight?: boolean
  isOpen?: boolean
}
export const LinksList = styled.div<LinksListPropsI>(
  ({ isLight = false, isOpen = false }) =>
    mediaQueries({
      alignItems: 'center',
      display: 'flex',
      flexWrap: ['wrap', 'wrap', 'nowrap'],
      justifyContent: 'center',
      marginLeft: [0, 0, 0, 'auto'],
      a: {
        color: isLight
          ? isOpen
            ? colorTextLight4
            : themesMap.light.colors.headlineColor
          : colorTextLight4,
        display: ['flex', 'flex', 'inline-flex'],
        fontFamily: '"Inter", sans-serif',
        fontSize: '1rem',
        fontWeight: 400,
        padding: [
          '1rem 2rem',
          '1rem 2rem',
          '1rem 1.5rem',
          '1rem 1.5rem',
          '1rem 2rem',
        ],
        textDecoration: 'none',
        width: ['100%', '100%', 'auto'],
        '&.active': {
          color: gold,
        },
      },
    })
)

export const Logo = styled.img({
  maxWidth: '153px',
})

export const Links = styled.div({
  a: {
    '&:not(:first-of-type)': {
      marginLeft: '1rem',
    },
  },
})

interface ToggleBarPropsI {
  isOpen: boolean
}
export const ToggleBar = styled.div<ToggleBarPropsI>(
  mediaQueries({
    background: 'white',
    display: ['block', 'block', 'block', 'none'],
    height: '2px',
    transition: 'transform .35s ease-in',
    width: '100%',
    '&:not(:first-of-type)': {
      marginTop: '.5rem',
    },
  }),
  ({ isOpen = false }) => ({
    '&:first-of-type': {
      transform: isOpen ? 'rotate(45deg) translate(.375rem, .1875rem)' : 'none',
    },
    '&:last-of-type': {
      transform: isOpen ? 'rotate(-45deg) translate(.25rem, -1px)' : 'none',
    },
  })
)

interface TogglePropsI {
  isLight?: boolean
}
export const Toggle = styled.div<TogglePropsI>(
  mediaQueries({
    display: ['block', 'block', 'block', 'none'],
    height: '1.5rem',
    width: '1.5rem',
  }),
  ({ isLight = false }) => ({
    [`${ToggleBar}`]: {
      backgroundColor: isLight
        ? themesMap.light.colors.headlineColor
        : themesMap.dark.colors.headlineColor,
    },
  })
)

interface WrapperPropsI {
  isLight: boolean
  isOpen: boolean
  isScrolled: boolean
}

export const Wrapper = styled.header<WrapperPropsI>(
  ({ isLight = false, isOpen = false, isScrolled = false }) =>
    mediaQueries({
      alignItems: 'center',
      backgroundColor: isLight
        ? 'white'
        : isScrolled
        ? themesMap.dark.colors.backgroundColor
        : themesMap.dark.colors.backgroundColor,
      display: 'flex',
      flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap'],
      height: ['auto', 'auto', 'auto', '5rem'],
      justifyContent: 'space-between',
      left: 0,
      marginLeft: 0,
      padding: '1rem',
      position: 'fixed',
      right: 0,
      top: 0,
      transition: 'margin-left 0.35s, width 0.35s',
      '& > a': {
        color: isLight ? 'white' : themesMap.dark.colors.backgroundColor,
        alignItems: 'center',
        display: 'inline-flex',
        minHeight: ['2rem', '2rem', '2rem', 'auto'],
        margin: 0,
      },
      width: '100%',
      zIndex: 2,
      '&.scrolled': {
        animation: `${scrolledAnimation} 0.5s ease`,
      },
      '&.open': {
        backgroundColor: themesMap.dark.colors.backgroundColor,
      },
      '&.unscrolled': {
        animation: `${unscrolledAnimation} 0.5s ease`,
      },
    })
)
