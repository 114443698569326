import styled from '@emotion/styled'
import { rgba } from 'polished'

import { colorButtonBackground, colorTextLabel } from '@lib/colors'
import { mediaQueries } from '@lib/mediaQueries'
import { Theme, themesMap } from '@components/undock/modules'

export const ButtonIcon = styled.img({
  marginRight: '.5rem',
  maxHeight: '2rem',
})

interface ButtonPropsI {
  borderless?: boolean
  theme?: Theme
}

const buttonStyles = {
  alignItems: 'center',
  border: 'solid 1px',
  borderRadius: '0.5rem',
  cursor: 'pointer',
  display: 'inline-flex',
  fontWeight: 600,
  height: '3rem',
  justifyContent: 'center',
  padding: '1rem',
  textDecoration: 'none',
  transition:
    'background-color .35s ease, border-color .35s, color .35s ease, box-shadow .35s ease',
  width: ['100%', '100%', 'auto'],
}

const primaryStyles = ({ theme = themesMap.light }: ButtonPropsI) => {
  return {
    ...buttonStyles,
    backgroundColor: theme.colors.primaryButtonBackground,
    borderColor: theme.colors.primaryButtonBackground,
    color: theme.colors.primaryButtonColor,
    '&:hover': {
      backgroundColor: colorButtonBackground,
      borderColor: colorButtonBackground,
      color: 'white',
    },
  }
}

export const PrimaryButton = styled.button<ButtonPropsI>(
  ({ theme = themesMap.light }) =>
    mediaQueries({
      ...primaryStyles({ theme }),
    })
)

export const PrimaryLink = styled.a<ButtonPropsI>(
  ({ theme = themesMap.light }) =>
    mediaQueries({
      ...primaryStyles({ theme }),
    })
)

const secondaryStyles = ({
  borderless = false,
  theme = themesMap.light,
}: ButtonPropsI) => {
  return {
    ...buttonStyles,
    backgroundColor: 'transparent',
    borderColor: borderless
      ? 'transparent !important'
      : rgba(colorTextLabel, 0.25),
    color: theme.colors.headlineColor,
    '&:hover': {
      backgroundColor: colorButtonBackground,
      borderColor: colorButtonBackground,
      color: 'white',
    },
  }
}

export const SecondaryButton = styled.button<ButtonPropsI>(
  ({ borderless = false, theme = themesMap.light }) =>
    mediaQueries({
      ...secondaryStyles({ borderless, theme }),
      color: theme.colors.headlineColor,
    })
)

export const SecondaryLink = styled.a<ButtonPropsI>(
  ({ borderless = false, theme = themesMap.light }) =>
    mediaQueries({
      ...secondaryStyles({ borderless, theme }),
      color: theme.colors.headlineColor,
    })
)
