import styled from '@emotion/styled'
import { mediaQueries } from '@lib/mediaQueries'

interface ContainerPropsI {
  hasHeader?: boolean
}

export const Container = styled.div<ContainerPropsI>(({ hasHeader = true }) =>
  mediaQueries({
    marginLeft: 0,
    padding: 0,
    transition: 'margin-left 0.35s, width 0.35s',
    width: '100%',
    '> :first-child': {
      paddingTop: hasHeader ? '8rem' : '2rem',
    },
  })
)
